<template>
  <div class="piechart" ref="chartdiv"></div>
</template>

<script>
/* Imports */
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export default {
  name: 'pieDH02Age',
  props: ['data'],
  watch: {
    data(newV, oldV) {
      console.log('pieDH02Age newV ::==', JSON.stringify(newV))
      this.builder(newV)
    }
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  methods: {
    builder(data) {
      console.log('pieDH02Age initial')
      let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart3D)
      chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

      chart.legend = new am4charts.Legend()

      // Add data
      chart.data = data

      let series = chart.series.push(new am4charts.PieSeries3D())
      series.dataFields.value = 'total'
      series.dataFields.category = 'age'
    }
  }
}
</script>

<style>
</style>