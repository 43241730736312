<template>
  <div class="p-grid p-fluid dashboard">
    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">ผู้สูงอายุในระบบ</span>
        <span class="detail">จำนวนผู้สูงอายุทั้งหมดในพื้นที่</span>
        <span class="count visitors">{{ statistics.elder }} คน</span>
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">อาชีพในระบบ</span>
        <span class="detail">จำนวนอาชีพในทั้งหมด</span>
        <span class="count purchases">{{ statistics.career }} อาชีพ</span>
      </div>
    </div>
    <div class="p-col-12 p-lg-4" v-if="level != 'AL'">
      <div class="card summary">
        <span class="title">หน่วยงานท้องถิ่น</span>
        <span class="detail">จำนวนหน่วยงานท้องถิ่น</span>
        <span class="count revenue">{{ statistics.area }} พื้นที่</span>
      </div>
    </div>
  </div>

  <div class="p-d-flex p-jc-end" v-if="userLevel == 'AC'">
    <div class="p-mr-2" style="width: 200px">
      <Dropdown
        id="areaId"
        v-model="criteria.areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="พื้นที่"
        :filter="true"
        @change="onChangeFilter(lotOfSurvey)"
        class="p-d-flex"
      />
    </div>
  </div>

  <Fieldset legend="แสดงสถานะการสำรวจของแต่ละพื้นที่">
    <div class="p-col-9">
        <div class="p-d-flex p-grid">
          <div class="p-mb-4 p-lg-4 p-fluid">
            <Button
              label="สำรวจครั้งที่ 1 สำรวจข้อมูลผู้สูงอายุเพื่อประเมินศักยภาพการทำงาน"
              class="p-button-sm p-mr-2 p-mb-2" 
              :class=" lotOfSurvey=='1'? 'p-button-primary' : 'p-button-outlined'"
              @click="onChangeFilter(1)"
            />
          </div>
          <div class="p-mb-4 p-lg-4 p-fluid">
            <Button
              label="สำรวจครั้งที่ 2 สำรวจข้อมูลผู้สูงอายุที่เข้าโปรแกรมยกระดับความสามารถในการทำงาน"
              class="p-button-sm p-mr-2 p-mb-2"
              :class=" lotOfSurvey=='2'? 'p-button-primary' : 'p-button-outlined'"
              @click="onChangeFilter(2)"
            />
          </div>
        </div>
      </div>
    <ColumnElderlyBar :data="chartData" v-if="level != 'AC'" />
    <ScrollPanel
      style="width: 100%"
      :style="{ height: criteria.areaId == 'ALL' ? '700px' : '200px' }"
      v-else
    >
      <ColumnElderlyClusterBar
        :data="chartData"
        :style="{ 'min-height': criteria.areaId == 'ALL' ? '650px' : '200px' }"
      />
    </ScrollPanel>
  </Fieldset>
  <!-- dh -->

  <!--
    <Fieldset legend="กราฟแสดงข้อมูลผู้สูงอายุแบ่งตามเพศ">
    <PieDH01Gender :data="dh01Data" :style="graphSize" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงข้อมูลผู้สูงอายุแบ่งตามช่วงอายุ">
    <PieDH02Age :data="dh02Data" :style="graphSize" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงข้อมูลผู้สูงอายุที่ทำงานอยู่กับไม่ได้ทำงาน">
    <PieDC02Elder :data="dc02Data" :style="graphSize" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงอาการปวดของร่างกาย">
    <PolarDH04Body :data="dh04Data" :style="graphSize" />
  </Fieldset>

  <Fieldset legend="กราฟแสดงข้อมูลคะแนนทักษะของผู้สูงอายุในแต่ละอาชีพ">
    <RadarDC04Occupation :data="dc04Data" :style="graphSize" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงข้อมูลสมรรถภาพสมอง (TMSE)">
    <ColumnDH03Brain :data="dh03Data" :style="graphSize" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงข้อมูลรายได้กับรายจ่ายต่อเดือน ของผู้สูงอายุ">
    <ColumnDC01Expenses :data="dc01Data" :style="graphSize" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงข้อมูลอาชีพที่ผู้สูงอายุต้องการทำในอนาคต">
    <ColumnDC03AgetoFuture :data="dc03Data" :style="graphSize" />
  </Fieldset>-->

  <!-- /dc -->
</template>
<script>
import * as _ from 'lodash'
import ColumnElderlyBar from '@/components/chart/ColumnElderlyBar'
import ColumnElderlyClusterBar from '@/components/chart/ColumnElderlyClusterBar'
import PieDH01Gender from '@/components/chart/PieDH01Gender'
import PieDH02Age from '@/components/chart/PieDH02Age'
import PieDC02Elder from '@/components/chart/PieDC02Elder'
import ColumnDH03Brain from '@/components/chart/ColumnDH03Brain'
import ColumnDC01Expenses from '@/components/chart/ColumnDC01Expenses'
import ColumnDC03AgetoFuture from '@/components/chart/ColumnDC03AgetoFuture'
import PolarDH04Body from '../components/chart/PolarDH04Body'
import RadarDC04Occupation from '../components/chart/RadarDC04Occupation'

export default {
  name: 'dashboard',
  layout: 'Private',
  data() {
    return {
      chartData: [],
      dh01Data: [],
      dh02Data: [],
      dh03Data: [],
      dh04Data: [],
      dc01Data: [],
      dc02Data: [],
      dc03Data: [],
      dc04Data: [],
      height: 400,
      criteria: {
        areaId: 'ALL'
      },
      lotOfSurvey: 1
    }
  },
  computed: {
    areas() {
      const areas = this.$store.state.area.areas
      console.log('areas ::==', areas)
      return [
        {
          areaName: 'ทั้งหมด',
          codeId: 'ALL'
        },
        ...areas
      ]
    },
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    graphSize() {
      return {
        'min-height': '450px',
        width: '100%'
      }
    },
    statistics() {
      const {
        codeId: userId,
        area,
        userLevel
      } = this.$store.getters['oauth/session']
      const statistics = this.$store.getters['statistics/toFormat']
      const { elder, elderArea } = statistics
      return {
        ...statistics,
        elder: 'AC' == userLevel ? elder : elderArea
      }
    },
    level() {
      const {
        codeId: userId,
        userLevel: level,
        area
      } = this.$store.getters['oauth/session']
      return level
    }
  },
  async created() {
    await this.$store.dispatch('area/fetchAreasOrderNameTh')

    const {
      codeId: userId,
      userLevel,
      area
    } = this.$store.getters['oauth/session']
    this.$store.dispatch('statistics/fecthStatisticsState', {
      areaId: area?.codeId
    })

    this.onChangeFilter(1)

    // save activity log
    try {
      await this.$store.dispatch('activity/saveActionActivityLog', {
        actMenu: 'DASHBOARD'
      })
    } catch (error) {
      console.log('saveActionActivityLog ::==', error)
    }
  },
  methods: {
    async onChangeFilter(lotSurvey) {
      /*const { originalEvent, value } = event
      console.log('originalEvent ::==', originalEvent)
      console.log('value ::==', value)*/

      const {
        codeId: userId,
        userLevel: level,
        area
      } = this.$store.getters['oauth/session']
      this.chartData = null;
      this.chartData = await this.fetchChartData(lotSurvey)
      //console.log('this.chartData  ::==' + JSON.stringify(this.chartData))

      try {
        let { areaId } = this.criteria

        if (level != 'AC') {
          areaId = area?.codeId
        }

        const {
          ageRange,
          careerNeed,
          ageIncomeExpend,
          career,
          gender,
          careerFlag,
          ageScore,
          painOrgan
        } = await this.$store.dispatch('pivot/fetchGraphSeriesElderly', {
          areaId: areaId == 'ALL' ? null : areaId
        })

        this.dh01Data = [
          { gender: 'ชาย', total: gender.male },
          { gender: 'หญิง', total: gender.female }
        ]
        this.dh02Data = [
          { age: 'ช่วงอายุ 60 - 65', total: ageRange?.age65 },
          { age: 'ช่วงอายุ 66 - 70', total: ageRange?.age70 },
          { age: 'ช่วงอายุ 70 ขึ้นไป', total: ageRange?.age71 }
        ]
        const {
          age6510,
          age6520,
          age6530,
          age7010,
          age7020,
          age7030,
          age7110,
          age7120,
          age7130
        } = ageScore
        this.dh03Data = [
          {
            category: 'ช่วงอายุ 60 - 65',
            first: age6510,
            second: age6520,
            third: age6530
          },
          {
            category: 'ช่วงอายุ 66 - 70',
            first: age7010,
            second: age7020,
            third: age7030
          },
          {
            category: 'ช่วงอายุ > 70',
            first: age7110,
            second: age7120,
            third: age7130
          }
        ]
        const { back, elbow, foot, hand, hip, knee, neck, scapula, shoulder } =
          painOrgan
        //  ['คอ', 'ไหล่', 'สะบัก', 'ข้อศอก', 'ข้อมือ']
        this.dh04Data = {
          labels: [
            'คอ',
            'ไหล่',
            'สะบัก',
            'ข้อศอก/แขน',
            'ข้อมือ/มือ/นิ้ว',
            'หลังส่วนเอว',
            'สะโพก/ต้นขา',
            'ข้อเข่า/น่อง',
            'ข้อเท้า/เท้า/นิ้ว'
          ],
          data: [neck, shoulder, scapula, elbow, hand, back, hip, knee, foot]
        }

        const {
          avg65Expend,
          avg65income,
          avg70Expend,
          avg70income,
          avg71Expend,
          avg71income
        } = ageIncomeExpend
        this.dc01Data = [
          {
            category: 'ช่วงอายุ 60 - 65',
            first: avg65Expend,
            second: avg65income
          },
          {
            category: 'ช่วงอายุ 66 - 70',
            first: avg70Expend,
            second: avg70income
          },
          {
            category: 'ช่วงอายุ > 70',
            first: avg71Expend,
            second: avg71income
          }
        ]
        const { c1Working, c2NonWorking, c99Refuse } = careerFlag
        this.dc02Data = [
          {
            status: 'ทำงานอยู่',
            total: c1Working
          },
          {
            status: 'ไม่ได้ทำงาน',
            total: c2NonWorking
          },
          {
            status: 'ปฏิเสธให้ข้อมูล',
            total: c99Refuse
          }
        ]

        const {
          c1Trade,
          c2Gardener,
          c3Rubber,
          c4Fruit,
          c5Farmer,
          c6None,
          c99Refuse: _careerRefuse
        } = careerNeed
        console.log('dc03Data ::== careerNeed ::==', careerNeed)
        this.dc03Data = [
          {
            country: 'อาชีพค้าขาย',
            visits: c1Trade
          },
          {
            country: 'อาชีพทำไร่/ทำสวน',
            visits: c2Gardener
          },
          {
            country: 'อาชีพกรีดยาง',
            visits: c3Rubber
          },
          {
            country: 'อาชีพทำสวนผลไม้',
            visits: c4Fruit
          },
          {
            country: 'อาชีพทำนา',
            visits: c5Farmer
          },
          {
            country: 'ไม่เลือก 7 อาชีพนี้',
            visits: c6None
          },
          {
            country: 'ปฏิเสธให้ข้อมูล',
            visits: _careerRefuse
          }
        ]

        const { farm, orcard, rice, rubber, trade } = career
        console.log('dc04Data ::== career ::==', career)
        this.dc04Data = {
          labels: [
            'อาชีพค้าขาย',
            'อาชีพทำไร่/ทำสวน',
            'อาชีพกรีดยาง',
            'อาชีพทำสวนผลไม้',
            'อาชีพทำนา'
          ],
          data: [trade, farm, rubber, orcard, rice]
        }
      } catch (error) {
        console.log('fetchGraphSeriesElderly ::==', error)
      }
    },
    async fetchChartData(lotSurvey) {
      // console.log(' this.data ::==' + JSON.stringify(this.data))
      const {
        codeId: userId,
        userLevel: level,
        area
      } = this.$store.getters['oauth/session']

      /*
    AL, OL --> กราฟแท่งเฉพาะพื้นที่ตัวเอง มี 2 แท่ง สำรวจแล้ว, ยังไม่สำรวจ
		AC --> กราฟแท่งแสดงข้อมูลจาก 4 พื้นที่ group ตามพื้นที่ แต่ละพื้นที่ มี 2 แท่ง สำรวจแล้ว, ยังไม่สำรวจ
    */
      const areaId = area?.codeId

      this.lotOfSurvey = lotSurvey

      let chartData = []
      if ('AC' != level) {
        console.log('level ::==', level)
        const { status, code, data } = await this.$store.dispatch(
          'pivot/fetchElderliesStatusSurvey',
          {
            areaId,
            lotSurvey
          }
        )
        if (code == 200) {
          const { survey, unSurvey } = data
          chartData = [
            {
              area: 'เฉพาะพื้นที่รับผิดชอบ',
              survey: survey,
              unSurvey: unSurvey,
            }
          ]
        }
      } else {
        const { areaId: criAreaId } = this.criteria
        const { status, code, data } = await this.$store.dispatch(
          'pivot/fetchElderliesGroupAreaStatusSurvey',
          {
            areaId: criAreaId == 'ALL' ? null : criAreaId ,
            lotSurvey: lotSurvey
          }
        )
        chartData = data
      }


      return new Promise((resolve) => {
        resolve(chartData)
      })
    }
  },
  components: {
    ColumnElderlyBar,
    ColumnElderlyClusterBar
    /*PieDH01Gender,
    PieDH02Age,
    PieDC02Elder,
    ColumnDH03Brain,
    ColumnDC01Expenses,
    ColumnDC03AgetoFuture,
    PolarDH04Body,
    RadarDC04Occupation*/
  }
}
</script>
<style scoped>
</style>