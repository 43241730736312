<template>
  <div class="linechart" ref="chartdiv"></div>
</template>

<script>
import * as _ from 'lodash'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_material from '@amcharts/amcharts4/themes/material'

am4core.useTheme(am4themes_material)

export default {
  name: 'columnElderlyClusterBar',
  props: ['data'],
  watch: {
    data(newV, oldV) {
      //console.log('newV ::==', JSON.stringify(newV))
      this.builder(newV)
    }
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  methods: {
    builder(data) {
      console.log('columnElderlyClusterBar initial')
      var chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)

      // Add data
      chart.data = data

      // Create axes
      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'area'
      categoryAxis.numberFormatter.numberFormat = '#'
      categoryAxis.renderer.inversed = true
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.renderer.cellStartLocation = 0.1
      categoryAxis.renderer.cellEndLocation = 0.9

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.opposite = true
      valueAxis.min = 0
      valueAxis.maxPrecision = 0

      // Create series
      function createSeries(field, name, color) {
        let series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueX = field
        series.dataFields.categoryY = 'area'
        series.name = name
        series.columns.template.tooltipText = '{name}: [bold]{valueX}[/]'
        series.columns.template.height = am4core.percent(100)
        series.sequencedInterpolation = true
        series.columns.template.fill = color
        series.columns.template.stroke = color

        let valueLabel = series.bullets.push(new am4charts.LabelBullet())
        valueLabel.label.text = '{valueX}'
        valueLabel.label.horizontalCenter = 'left'
        valueLabel.label.dx = 10
        valueLabel.label.hideOversized = false
        valueLabel.label.truncate = false

        let categoryLabel = series.bullets.push(new am4charts.LabelBullet())
        categoryLabel.label.text = '{name}'
        categoryLabel.label.horizontalCenter = 'right'
        categoryLabel.label.dx = -10
        categoryLabel.label.fill = am4core.color('#fff')
        categoryLabel.label.hideOversized = false
        categoryLabel.label.truncate = false
      }

      createSeries('survey', 'สำรวจแล้ว', '#20d077')
      createSeries('unSurvey', 'รอการสำรวจ', '#f9c851')
    }
  }
}
</script>

<style>
</style>